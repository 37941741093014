var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","width":"60%"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.isEdit ? 'Editar' : 'Crear')+" charla")])]),_c('v-card-text',[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row"},[(!_vm.conferenceBookingTime)?_c('div',{staticClass:"col-12"},[_c('p',[_vm._v(" Seleccione un salón en el horario deseado, tendrás 10 minutos para completar tu reserva.")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text text-none elevation-0",attrs:{"color":"primary"},on:{"click":_vm.onSelectTime}},on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" fa-calendar ")]),_vm._v(" Seleccionar espacio ")],1)]}}],null,false,2380449780)},[_c('span',[_vm._v(" Seleccione un salón en el horario deseado ")])])],1):_vm._e()]),(_vm.conferenceBookingTime)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pb-0"},[_c('h4',{staticClass:"mb-4 text--primary"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" mdi-calendar ")]),_c('strong',[_vm._v("Espacio seleccionado:")])],1)]),_c('div',{staticClass:"col-3 py-0 pl-8"},[_c('p',{staticClass:"text--primary mt-1"},[_c('strong',[_vm._v("Fecha:")]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.conferenceBookingTime.availableDate)))])]),_c('div',{staticClass:"col-3 py-0 pl-8"},[_c('p',{staticClass:"text--primary mt-1"},[_c('strong',[_vm._v("Hora:")]),_vm._v(" "+_vm._s(_vm.conferenceBookingTime.time))])]),_c('div',{staticClass:"col-3 py-0 pl-8"},[_c('p',{staticClass:"text--primary mt-1"},[_c('strong',[_vm._v("Salón:")]),_vm._v(" "+_vm._s(_vm.conferenceBookingTime.conferenceRoom))])]),_c('div',{staticClass:"col-3 py-0 text-right"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text text-none elevation-0 mr-2 ml-3",attrs:{"color":"primary"},on:{"click":_vm.onSelectTime}},on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" fa-calendar-times ")]),_vm._v(" Editar espacio ")],1)]}}],null,false,1423346411)},[_c('span',[_vm._v(" Seleccione un salón en el horario deseado ")])])],1),_c('div',{staticClass:"col-12 pb-0"},[_c('h4',{staticClass:"mb-4 text--primary"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" mdi-bullhorn ")]),_c('strong',[_vm._v("Información de la charla:")])],1)]),_c('div',{staticClass:"col-6 pb-0"},[_c('validation-provider',{attrs:{"name":"nombre de la charla","vid":"name","rules":"required|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre de la charla*","outlined":"","dense":"","error-messages":errors,"required":""},model:{value:(_vm.model.conferenceName),callback:function ($$v) {_vm.$set(_vm.model, "conferenceName", $$v)},expression:"model.conferenceName"}})]}}],null,false,4173964281)})],1),_c('div',{staticClass:"col-6 pb-0"},[_c('validation-provider',{attrs:{"name":"nombre del conferencista","vid":"speaker","rules":"required|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre del conferencista*","outlined":"","dense":"","error-messages":errors,"required":""},model:{value:(_vm.model.speaker),callback:function ($$v) {_vm.$set(_vm.model, "speaker", $$v)},expression:"model.speaker"}})]}}],null,false,2438041211)})],1),_c('div',{staticClass:"col-12 pb-0"},[_c('validation-provider',{attrs:{"name":"palabras clave","vid":"keywords","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.keywords,"multiple":true,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Palabras clave","error-messages":errors},model:{value:(_vm.model.keywords),callback:function ($$v) {_vm.$set(_vm.model, "keywords", $$v)},expression:"model.keywords"}})]}}],null,false,3979761744)})],1),_c('div',{staticClass:"col-12 pb-0"},[_c('h4',{staticClass:"mb-4 text--primary"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" mdi-information ")]),_c('strong',[_vm._v("Resumen:")])],1)]),_c('div',{staticClass:"col-12 py-0 pl-4"},[_c('p',{staticClass:"justify"},[_vm._v(" Si aún no tienes los datos de la charla te recomendamos escribir en los campos las palabras "),_c('strong',[_vm._v("por definir")]),_vm._v(" para que puedas solicitar el espacio. Siempre podrás volver sobre la charla para editar la información. Recuerda que una vez aprobada la charla, la información ingresada será visible al público. ")])]),_c('div',{staticClass:"col-12 pb-0"},[_c('validation-provider',{attrs:{"name":"resumen","vid":"conferenceName","rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":"","outlined":"","clearable":"","type":"text","label":"Resumen*","error-messages":errors,"clear-icon":"mdi-close-circle"},model:{value:(_vm.model.summary),callback:function ($$v) {_vm.$set(_vm.model, "summary", $$v)},expression:"model.summary"}})]}}],null,false,3686788074)})],1)]):_vm._e()])],1),_c('v-card-actions',[_c('v-spacer'),(_vm.conferenceBookingTime && !_vm.isEdit)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" Solicitar espacio ")]):_vm._e(),(_vm.conferenceBookingTime && _vm.isEdit)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")]):_vm._e(),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('conference-booking-time-modal',{ref:"ConferenceBookingTimeModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }